import { v4 } from "uuid";
import FileUploader from "./FileUploader";

/// Takes care of uploading image files to the Firebase Cloud Storage.
class ImageUploader {

  static readonly maxFileSize = 10 * 1024 * 1024; // 10 MB
  
  private readonly fileUploader: FileUploader;
  
  private readonly path: string;

  constructor() {
    this.fileUploader = new FileUploader(ImageUploader.maxFileSize)
    
    const uploadBatchId = v4();
    this.path = `jobs/images/${uploadBatchId}`;
  }

  async uploadFile(file: File) {
    return this.fileUploader.uploadFile(file, this.path);
  }

  isFileTooLarge(file: File) {
    return this.fileUploader.isFileTooLarge(file);
  }

}

export default ImageUploader;
