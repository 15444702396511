import React, { useState } from 'react';
import './Upload.scss';
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { loadStripe, Stripe } from '@stripe/stripe-js';
import Button from '@mui/material/Button';
import ImageUploader from './logic/ImageUploader';
import { Alert, AlertColor, Box, Card, CardContent, FormControl, IconButton, ImageList, ImageListItem, ImageListItemBar, InputLabel, List, ListItem, ListItemIcon, ListItemText, ListSubheader, MenuItem, Select, Step, StepLabel, Stepper } from '@mui/material';
import { PhotoCamera, ShoppingCartCheckout, NavigateNext } from '@mui/icons-material';
import { Emoji } from 'react-apple-emojis';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from "axios";

type SelectedFile = {
  name: string;
  url?: string | undefined;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
if (!publishableKey) {
  throw new Error('No Stripe publishable key defined!');
}

let stripePromise: Promise<Stripe | null> | undefined;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(publishableKey);
  }

  return stripePromise!;
};

type UploadProps = {
  minimumNumberOfImages: number;
};

const Upload = (props: UploadProps) => {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);

  // Initialize analytics
  getAnalytics(app);
  
  // Initialize firestore
  const firestore = getFirestore(app);
  if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
    connectFirestoreEmulator(firestore, "localhost", 8080);
  }

  // Initialize storage
  const storage = getStorage(app);
  if (process.env.REACT_APP_FIREBASE_USE_EMULATOR === "true") {
    connectStorageEmulator(storage, "localhost", 4002);
  }

  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const [checkoutPending, setCheckoutPending] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [personType, setPersonType] = useState<string>('man');
  const [activeStep, setActiveStep] = useState<number>(0);
  const [step2AlreadySeen, setStep2AlreadySeen] = useState(false);
  const [step3AlreadySeen, setStep3AlreadySeen] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);

	const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setUploadActive(true);

    let newSelectedFiles = [...selectedFiles];
    let promises: Promise<string>[] = [];
    
    // Start uploading files
    if (event.target.files && event.target.files.length > 0) {

      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const imageUploader = new ImageUploader();
        if (imageUploader.isFileTooLarge(file)) {
          throw new Error('File too large: ' + file.name);
        }

        const selectedFile: SelectedFile = {
          name: file.name
        };

        const promise = imageUploader.uploadFile(file).then(url => selectedFile.url = url);
        promises.push(promise);
        
        newSelectedFiles.push(selectedFile);
      }
    }

    // This will clear the input value selection. This fixes the change event not being called if image was removed and then
    // selected one more time.
    event.target.value = '';
    
    try {
      await Promise.all(promises);
    }
    catch {
      return;
    }
    finally {
      setUploadActive(false);
    }

    setSelectedFiles(newSelectedFiles);

    refreshNumberOfRemainingImages(newSelectedFiles.length);
	};

  const deselectFile = (selectedFile: SelectedFile) => {
    const newSelectedFiles = selectedFiles.filter(x => x !== selectedFile);
    setSelectedFiles(newSelectedFiles);

    refreshNumberOfRemainingImages(newSelectedFiles.length);
  };

  const increaseActiveStep = () => {
    const nextActiveStep = activeStep + 1;
    setActiveStep(nextActiveStep);

    if (nextActiveStep === 1) {
      setStep2AlreadySeen(true);
    }

    if (nextActiveStep === 2) {
      setStep3AlreadySeen(true);
    }
  };

  const refreshNumberOfRemainingImages = (numberOfSelectedFiles: number) => {
    const enoughImagesSelected = numberOfSelectedFiles >= props.minimumNumberOfImages;
    const showNumberOfImagesStatus = numberOfSelectedFiles > 0 && !enoughImagesSelected;
    if (showNumberOfImagesStatus) {
      setAlertSeverity("error");
      setAlertMessage(`Please upload at least ${props.minimumNumberOfImages - numberOfSelectedFiles} more images of yourself.`);
      return true;
    }

    setAlertSeverity(null);
    setAlertMessage(null);

    return false;
  }
  
  const handleCheckoutButtonClicked = async () => {
    if (refreshNumberOfRemainingImages(selectedFiles.length)) {
      return;
    }

    setCheckoutPending(true);

    const stripe = await getStripe();
    if (!stripe) {
      setAlertMessage("Unable to contact the payment provider :(");
      setAlertSeverity("error");
      return;
    }

    const createJobUrl = `${process.env.REACT_APP_BACKEND_URL}/jobs/checkout`;

    try {
      const fileURLs = selectedFiles.map(selectedFile => selectedFile.url);
      if (fileURLs.length === 0) {
        throw new Error('No file URLs!');
      }
      
      const response = await axios.post(createJobUrl, {
        personType: personType,
        fileURLs: fileURLs,
        successUrl: `${process.env.REACT_APP_BASE_URL}/success`,
        cancelUrl: `${process.env.REACT_APP_BASE_URL}`
      });
      if (!response.data.url) {
        throw new Error('Unable to get checkout URL');
      }

      window.location.href = response.data.url;
    }
    catch (error: any) {
      if (error.message) {
        setAlertMessage(error.message);
        setAlertSeverity("error");
      }
    }

    setCheckoutPending(false);
  };

  const genderSection = activeStep === 0 ? (
    <Card>
      <CardContent>
        <Box textAlign="center">
          <FormControl sx={{width: 140, textAlign: 'left'}}>
            <InputLabel id="select-label">I'm a</InputLabel>
            <Select labelId="select-label" id="select" value={personType} label="I'm a" onChange={(e) => setPersonType(e.target.value)}>
              <MenuItem value={'man'}>Man</MenuItem>
              <MenuItem value={'woman'}>Woman</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{marginTop: '20px'}}>
            <Button variant="contained" onClick={increaseActiveStep} endIcon={<NavigateNext />}>Next</Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : null;

  const uploadAlert = alertSeverity !== null && alertMessage !== null ? (
    <Alert severity={alertSeverity} sx={{marginTop: '20px'}}>
      {alertMessage}
    </Alert>
  ) : null;
  const nextButton = selectedFiles.length >= props.minimumNumberOfImages ? (
    <Button variant="contained" onClick={increaseActiveStep} endIcon={<NavigateNext />}>Next</Button>
  ) : null;
  const box = uploadAlert !== null || nextButton !== null ? (
    <Box sx={{marginTop: '20px'}}>
      {nextButton}
      {uploadAlert}
    </Box>
  ) : null;
  const imageList = selectedFiles.length > 0 ? (
    <ImageList cols={3}>
      {selectedFiles.map(selectedFile => (
        <ImageListItem key={selectedFile.name}>
          <img
            src={`${selectedFile.url}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${selectedFile.url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={selectedFile.name}
            loading="lazy"
          />
          <ImageListItemBar actionIcon={
            <IconButton sx={{ color: 'rgba(255, 255, 255, 0.54)' }} onClick={() => deselectFile(selectedFile)}>
              <DeleteIcon />
            </IconButton>
            }
          />
        </ImageListItem>
      ))}
    </ImageList>
  ) : null;
  const uploadButton = !uploadActive ? (
    <Button variant="contained" component="label" startIcon={<PhotoCamera />}>
      Upload your images
      <input hidden accept="image/*" multiple type="file" onChange={handleFileSelected} />
    </Button>
  ) : null;
  const uploadIndicator = uploadActive ? (
    <LoadingButton loading>
      Uploading &ellipsis;
    </LoadingButton>
  ) : null;
  const uploadImagesSection = activeStep === 1 ? (
    <Card>
      <CardContent>
        <List
          subheader={<ListSubheader>Guidelines</ListSubheader>}
        >
          <ListItem>
            <ListItemIcon>
              <Emoji className="emoji top0" name="check-mark-button" />
            </ListItemIcon>
            <ListItemText>At least {props.minimumNumberOfImages} photos of yourself</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Emoji className="emoji top0" name="check-mark-button" />
            </ListItemIcon>
            <ListItemText>Use photos with different backgrounds</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Emoji className="emoji top0" name="check-mark-button" />
            </ListItemIcon>
            <ListItemText>Use photos shot from different camera angles</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Emoji className="emoji top0" name="check-mark-button" />
            </ListItemIcon>
            <ListItemText>At least 512x512 size</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Emoji className="emoji top0" name="cross-mark" />
            </ListItemIcon>
            <ListItemText>Don't use selfies</ListItemText>
          </ListItem>
        </List>
        
        <Box textAlign="center">
          {uploadButton}
          {uploadIndicator}
          {imageList}
          {box}
        </Box>
      </CardContent>
    </Card>
  ) : null;

  const generateAvatarSection = activeStep === 2 ? (
    <Card>
      <CardContent>
        <Box textAlign="center">
          By proceeding you will get 100 high quality images generated by AI. Images are usually generated in an hour and they are sent to your email.
          <Box sx={{marginTop: '20px'}}>
            <Button variant="contained" onClick={handleCheckoutButtonClicked} disabled={checkoutPending} startIcon={<ShoppingCartCheckout />}>Buy for 40$</Button>
            <Alert severity="info" sx={{marginTop: '20px'}}>
              Please note that due to GPU costs we don't accept refunds.
            </Alert>
          </Box>
        </Box>
      </CardContent>
    </Card>
  ) : null;

  const step1Enabled = true;
  const step2Enabled = step2AlreadySeen;
  const step3Enabled = step3AlreadySeen;

  return (
    <>
      <Stepper activeStep={activeStep} sx={{marginTop: '40px', marginBottom: '40px', marginLeft: 'auto', marginRight: 'auto', width: 500}}>
        <Step key="1">
          <StepLabel className={step1Enabled ? 'enabled' : undefined} onClick={() => step1Enabled && setActiveStep(0)}>Choose gender</StepLabel>
        </Step>
        <Step key="2">
          <StepLabel className={step2Enabled ? 'enabled' : undefined} onClick={() => step2Enabled && setActiveStep(1)}>Upload photos</StepLabel>
        </Step>
        <Step key="3">
          <StepLabel className={step3Enabled ? 'enabled' : undefined} onClick={() => step3Enabled && setActiveStep(2)}>Generate avatar</StepLabel>
        </Step>
      </Stepper>
      {genderSection}
      {uploadImagesSection}
      {generateAvatarSection}
    </>
  );
}

export default Upload;