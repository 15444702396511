import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Upload from './Upload';
import Error from './Error';
import Success from './Success';
import { Emoji, EmojiProvider } from 'react-apple-emojis';
import emojiData from 'react-apple-emojis/src/data.json'
import CssBaseline from '@mui/material/CssBaseline';
import { AppBar, Box, Container, createTheme, Link, ThemeProvider, Toolbar, Typography } from '@mui/material';
import MonochromePhotosRoundedIcon from '@mui/icons-material/MonochromePhotosRounded';

const minimumNumberOfImages = 5;

const App = () => {
  const currentYear = new Date().getFullYear();

  const themeLight = createTheme({
    palette: {
      background: {
        default: "rgb(231, 235, 240)"
      }
    }
  });
  
  return (
    <ThemeProvider theme={themeLight}>
      <EmojiProvider data={emojiData}>
            <CssBaseline />
            
            <AppBar position="static">
              <Container maxWidth="xl">
                <Toolbar disableGutters>
                  <MonochromePhotosRoundedIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                  <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                    sx={{
                      mr: 2,
                      display: { xs: 'none', md: 'flex' },
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                  >
                    avatar-booth.com
                  </Typography>
                </Toolbar>
              </Container>
            </AppBar>

            <Box sx={{marginLeft: 'auto', marginRight: 'auto', width: 600}}>
              <Box sx={{ fontSize: 32, fontWeight: 'light', marginTop: '40px' }}>
                Generate your profile picture using AI.
              </Box>

              <Router>
                <Routes>
                  <Route path="/success" element={<Success />} />
                  <Route path="/error" element={<Error />} />
                  <Route path="/" element={<Upload minimumNumberOfImages={minimumNumberOfImages} />} />
                </Routes>
              </Router>

              <Box sx={{marginTop: '40px', marginBottom: '40px', fontSize: 14, textAlign: 'center' }}>
                  Made with <Emoji className="emoji top5" name="red-heart" /> in {currentYear}. Based on <Link href="https://arxiv.org/abs/2208.12242">DreamBooth</Link>.
              </Box>
              
              <input type="hidden" name="fb-project-id" value={process.env.REACT_APP_FIREBASE_PROJECT_ID} />
              <input type="hidden" name="app-version" value={process.env.REACT_APP_VERSION} />
            </Box>    

          </EmojiProvider>
    </ThemeProvider>
  );
};

export default App;
