import React from 'react';

const Error = () => {
  return (
    <>
      Error :(
    </>
  );
};

export default Error;
