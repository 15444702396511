import React from 'react';

const Success = () => {
  return (
    <>
      <h2>Thank you for your purchase!</h2>
      <div>You will receive your AI generated images on your email in the next hours.</div>
    </>
  );
};

export default Success;
