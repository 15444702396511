// import Bugsnag from "@bugsnag/js";
import { getStorage, ref, uploadBytes, updateMetadata, getDownloadURL } from "firebase/storage";
import { v4 } from 'uuid';

/// Takes care of uploading files to the Firebase Cloud Storage.
class FileUploader {

  private readonly maxFileSize: number;

  constructor(maxFileSize: number) {
    this.maxFileSize = maxFileSize;
  }

  async uploadFile(file: File, path: string) {
    if (this.isFileTooLarge(file)) {
      throw new Error('File too large');
    }
    
    const fileId = v4();
    const pathWithFileId = `${path}/${fileId}`;
    const storage = getStorage();
    const imageRef = ref(storage, pathWithFileId);
    
    try {
      // Upload file
      const snapshot = await uploadBytes(imageRef, file);
      console.log(`Uploaded a file with ID: ${fileId}`);
      
      // Set it's filename (this way we assure that the filename when user downloads this attachment is the same as it was when he uploaded it)
      const newMetadata = {
        contentDisposition : "attachment; filename=" + file.name
      }
      await updateMetadata(imageRef, newMetadata)
      
      // Get the download url of the uploaded image
      const url = await getDownloadURL(snapshot.ref);
      console.log(`Url of file with ID ${fileId}: ${url}`);
      return url as string;
    }
    catch (error) {
      // Bugsnag.notify(error);
      console.log(`Error while uploading file to firebase storage: ${error}`);
      throw error;
    }
  }

  isFileTooLarge(file: File) {
    return file.size > this.maxFileSize;
  }

}

export default FileUploader;
